//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ResponsiveImage from "@/components/common/ResponsiveImage";
import CommonBtnText from "@/components/common/buttons/TextBtn";
import addToWishlist from "~/assets/js/eSputnik/addToWishlistEvent";
import { mapGetters } from "vuex";
import bnpl from '~/assets/img/icons/0-0-4.svg?raw'

export default {
    name: "Item",
    components: { CommonBtnText, ResponsiveImage },
    props: {
        item: {
            type: Object,
            required: true
        },
        slideWidth: {
            type: Array,
            default: () => {
                return [254, 226, 131];
            }
        },
        position: {
            type: Number,
            default: 1
        },
        widgetName: {
            type: String,
            default: ""
        },
        utm: {
            type: String,
            default: ""
        }
    },
    data() {
        return {
            bnplIcon: bnpl,
        }
    },
    computed: {
        ...mapGetters({
            isProductFavorite: "favorite/isProductFavorite",
        }),
        productWidth() {
            let index = 2;
            if (this.$breakpoint.laptopSmallUp) {
                index = 0;
            } else if (this.$breakpoint.tabletSmallUp) {
                index = 1;
            } else {
                index = 2;
            }
            return `${ this.slideWidth[index] }px`;
        },
        isFavorite() {
            return this.isProductFavorite(this.item.id);
        },
        priceWobbler: function () {
            if (Array.isArray(this.item.wobblers)) {
                return this.item.wobblers.find(el => el.type && el.type === 'discount') || null;
            }else {
                return null;
            }
        },
        wobblerBNPL() {
            if (Array.isArray(this.item.wobblers)) {
                return this.item.wobblers.find(el => el.type === 'bnpl') || '';
            } else {
                return '';
            }
        },
        hasSale() {
            return this.item.price.sale !== this.item.price.first
        }
    },

    methods: {
        imageUrl(image) {
            return ImageHelper.getMediaStructure(image.id, AppConfig.image.fileExtensions.productCard, this.item.displayName, this.item.displayName, ImageSizes.productCarousel, 100);
        },
        saveListName() {
            this.$ga.onProductInListClick(this.item.id, this.position, this.widgetName);
        },
        sendClick() {
            this.saveListName();
            this.$ga.sendProductClick({
                name: this.item.displayName,
                id: this.item.id,
                price: this.item.price.sale,
                brand: this.item.division,
                category: this.item.productPath || [],
                variant: "adidas",
                position: this.position,
                list: this.widgetName
            });
        },
        onMouseUp(e) {
            if (e.button === 1) {
                this.sendClick();
            }
        },
        toggleFavorite() {
            if (this.isFavorite) {
                this.$store.dispatch("favorite/removeFromFavorite", this.item.id);
            } else {
                this.$store.dispatch("favorite/addToFavorite", this.item.id);
                addToWishlist(this.item);
                this.$gtag("event", "add_to_wishlist", {
                    value: this.item.price.sale,
                    items: [
                        {
                            id: this.item.id,
                            google_business_vertical: "retail"
                        }
                    ]
                });
                // this.$ga.sendSimpleEvent('add_to_wishlist')
            }
        },
    }
};
